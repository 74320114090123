.projectDone{
    display: flex;
    background: #f4f4f4;
    width: 85%;
    margin-left: 4%;
    margin-top: -25px;
    z-index: 1;
    position: sticky;
    margin-bottom: 2rem;
    height: 9rem;
    border-radius: 16px;  
  justify-content: space-between;
    padding: 24px;

}
.marketing p{
    color: #ff3d00;
    font-size: 19px;
    font-weight: 600;
    padding-bottom: 24px;
    /* margin-left: 23%; */
    /* padding: 1rem; */
}
.projectDone h3{
    font-size: 26px;
    font-weight: 650;
    /* padding: 10px; */
    margin-bottom: 10px;
    margin-top:-22px

}
.arrowIcon{
 font-size: 30px;
margin-left: 7rem;
margin-top: 1rem;
color: #fff;
background: #000;
border-radius: 50%;
}
.projectDoneDetails{
    margin-top: 3%;
}
.ProjectOptions{
    display: flex;
    margin: auto;
    text-align: center;
    justify-content: center;
    background-color: #e1e1e1;
    width: 46%;
    margin-top: 2rem;
    height: 51px;
    padding-top: 9px;
    border-radius: 10px;
}

.ProjectOptions span{
    font-size: 22px;
    margin-left: 8px;
    background-color: #fff;
    padding: 8px;
}
.ProjectOptions span:hover{
    background-color: #ff3d00;
    color: #fff;
}
@media (max-width:768px) {
    .projectMade img{
        height: 100%;
        width: 100%;
    }    
    .projectDone {
        margin-left: 7%;
    }
    .projectDone h3{
        font-size: 20px;
    }
    .marketing p{
        font-size: 15px;
    }
    .arrowIcon{
        margin-left: 3rem;
        margin-top: 2rem;
    }
}
@media (max-width:425px) {
    .projectDone {
        margin-left: 7%;
    }
    .projectDone h3{
        font-size: 20px;
    }
    .marketing p{
        font-size: 15px;
    }
    .arrowIcon{
        margin-left: 3rem;
        margin-top: 2rem;
    }
}