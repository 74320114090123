/* .team h6{
    width: 27%;
    margin-left: -5px;
} */
.teamDetailMember img{
    margin-top: 3rem;
    height: 30vw;

}
.aboutTeamMember p{
    font-size:18px;
}
.teamMemeberDetails{
    background-color: #fff;
}
.aboutTeamMember{
    margin-top: 9%;
}
.howToContact{
    display: flex;
    justify-content: space-between;
    text-align: center;
}
.call{
    box-shadow: 5px 5px 5px 5px rgb(212, 210, 210);
    width: 40%;
    height: 8vw;
    margin-top: 1rem;
}
.phoneIcon{
    margin-top: 10px;
    margin-bottom: 10px;
    font-size:20px ;
    color: #ff3d00;
}
.socialMediaSites{
    display: flex;
    margin-top: 1rem;
    padding: 10px;
}
.socialMedia{
    background: #e5e7e9;
    margin-left: 10px;
    border-radius: 20px;
    margin-top: 2px;
}
.socialMediaIcons{
    margin: 8px;
    font-size: 20px;
}
.skill-bar{
    background-color: #ff3d00;
    height: 10px;
    width: 74%;
    border-radius: 20px;
    margin-bottom: 30px;
    margin-top: 6px;
}
.skill-percent{
    color: #ff3d00; 
    font-size: 20px;   
    font-weight: 600;
    margin-left: 45%;
}
.skills span{
    font-size: 18px;
}
.skillHeading h2{
    font-weight: 700;
    font-size: 35px;
    margin-top: 1rem;
}
.loctionIcon{
    color: #ff3d00;
    font-size: 28px;
    margin-right:10px;
}
.location{
    display: flex;
    margin-top: 1rem;
}
.formAndLocation{
    margin-bottom: 1rem;
}