.aboutUs{
    background-image: url(https://res.cloudinary.com/dixfg1bvv/image/upload/v1722488439/breadcumb-bg_l3dos6.png);
    /* background-color: red; */
    background-repeat:no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    color: #fff;
    text-align: center;
 }
 .AboutUSHeader h1{ 
    padding: 10rem; 
    font-weight: 700;
    font-size: 50px;
 }
 .About h6{
    font-size: 20px;
    margin-top: -8rem;
    border-radius: 10px;
    background-color: #161a2b;
    border: solid #40465d;
    /* width: 26%; */
    height: 3rem;
    padding: 8px;
}
 .About span{
    color: #ff3d00;
 }
 .About{
    text-align: center;
    /* margin-left: 44%; */
    display: flex;
    justify-content: center;
 }
.AboutSolution h1{
    font-size: 50px;
    font-weight: 700 ;
}
.AboutSolution p{
    font-size: 23px;
}
.AboutSolution span{
    color: #ff3d00;
}
.bestItSolutions{
    display: flex;
    justify-content: space-between;
}
.solutionsList{
    font-size: 20px;
    margin-top: 1rem;
    
}
.solutionsList li{
    list-style:none;
    margin-top: 22px;
}
.circleIcon{
    color: #ff3d00;
    font-size: 10px;
    margin-right: 3px;
    margin-top: -2px;
}
.aboutClients{
    background-color: #dcd7d778;
    height: 8vw;
    width: 13vw;
    text-align: center;
    margin-left: -5rem;
    margin-top: -1.3rem;
    border-radius: 8px;
}
.countup{
    font-size: 40px;
    font-weight:700;
    color: #ff3d00;
    /* margin-top: 2rem; */
    display: block;
    padding-top: 1rem;
}
.aboutImage{
    padding: 3rem;
    margin-top: 4rem;
}
.aboutImage img{
    height: 100%;
    width: 100%;
}
.ExploreMoreBtn{
    color: #fff;
    background-color: #ff3d00;
    border: none;
    border-radius: 30px;
    height: 3rem;
    width: 9vw;
    margin-top: 2rem;
    margin-left: 2rem;
}
@media (max-width: 768px) {
    .row, .container-fluid {
      padding-left: 0;
      padding-right: 0;
    }
}
@media (max-width:1054px) {
    .aboutUs{
        height: 100%;
        width: 100%;
    }
    .counterImage{
        margin-left: -46px;
    }
    .ExploreMoreBtn{
        width: 20vw;
        margin-bottom: 1rem;
    }
}
@media (max-width:900px) {
    .aboutUs{
        height: 100%;
        width: 100%;
    }
    .aboutClients{
        height: 20vw;
        width: 20vw;
        margin-left: 20px;
    }
    .AboutUSHeader h1{
        padding: 8rem;
      
    }
    .About h6{
        margin-top: -5rem;

    }
    .AboutSolution h1{
        font-size: 40px;
    }
    .AboutSolution p {
        font-size: 20px;
    }
    .solutionsList {
        font-size: 18px;
        margin-top: 1rem;
        margin-left: -25px;
    }
    .counterImage{
        margin-left: 4rem;
    }
    .ExploreMoreBtn{
        width: 20vw;
        margin-bottom: 1rem;
    }
}
@media (max-width:767px) {
    .aboutUs{
        height: 100%;
        width: 100%;
        
    }
    .AboutSolution h1{
        font-size: 28px;
    }
    .AboutSolution p{
        font-size: 17px;
    }
    .solutionsList{
        font-size: 17px;
        margin-left: -27px;
    }
    .countup{
        font-size: 30px;
    }
    .aboutClients p{
        font-size: 17px;
    }
    .aboutClients{
        height: 31vw;
        width: 30vw;
        margin-left: 6px;
    
    }
    .AboutUSHeader h1{
        padding: 6rem;
        font-size: 25px;
    }
    
    .counterImage{
        margin-left: 2rem;
    }
    .ExploreMoreBtn{
        width: 35vw;
        /* margin-bottom: 1rem; */
    }
    .aboutImage img{
        display: none;
    }
    .aboutImage{
        padding: 0;
        margin-top:0 ;
    }
}