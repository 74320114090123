.solutionImage img{
    /* height: 15vw; */
    margin-top: 3rem;
    width: 80%;
}
.solutions{
    margin-top:4rem ;
    /* margin-left: 1rem; */
}
.GoldVision h6{
    color: #ff3d00;
    border-radius: 30px;
    background-color: rgba(255, 60, 0, 0.03137254901960784);
    border: 1px solid rgba(122, 122, 122, 0.15);
    /* font-size: 10px; */
    font-weight: 600;
    width: 35%;
    text-align: center;
    padding: 1rem;
}
.modernBusiness span{
    color: #ff3d00;
}
.modernBusiness h1{
    font-weight: 700;
}
.solutionImage p{
    margin-top: -19%;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    margin-left: 2rem;
}
.essentailSolution{
    margin-bottom: 2rem;
}
.cloudSolution{
    padding: 7px;
}
.cloudSolution img{
    margin-right: 10px;
}
@media (max-width:1024px) {
    .GoldVision h6{
        width: 48%
    }
    .essentailSolution{
        margin-top: 5rem;
    }    
    .solutionImage img{
        height: auto;
        width: 100%;
        margin-top: 6rem;
    }
    .solutionImage p{
        margin-top: -10rem;
        margin-left: 4rem;
        font-size: 22px;
    }
    .modernBusiness h1{
        font-size: 38px;
    }
}
@media (max-width:900px) {
    .GoldVision h6{
        width: 64%
    }
    .essentailSolution{
        margin-top: 5rem;
    }    
    .solutionImage img{
        height: 100%;
        width: 100%;
        margin-top:0rem;
    }
    .solutionImage p{
        margin-top: -8.5rem;
        margin-left: 5rem;
        font-size: 20px;
    }
    .modernBusiness h1{
        font-size: 38px;
    }
    .solutions {
        margin-top: 4rem;
        /* margin-left: 12rem; */
    }
    .teamMembers h6{
        width: 66%;
        margin: auto;
        margin-top: 4rem;
        margin-bottom: 2rem;
        font-weight: 700;
    }
}
@media (max-width:767px) {
    .solutionImage p{
        margin-top: -5rem;
        margin-left: 1.4rem;
        font-size: 14px;
    }
    .modernBusiness h1 {
        font-size: 32px;
    }
    .solutions {
        margin-top: 4rem;
        /* margin-left: 1rem; */
    }
    .essentailSolution{
        margin-bottom: 0rem;
    }
    .solutionImage img{
        height: 100%;
        width: 100%;
        margin-top: 0rem;
        margin-bottom: 0;
    
    }
    .success {
        margin-left: 0%;
        margin-bottom: 2%;
        padding-top: 3rem;
    }
  }
  @media (max-width:400px) {
    .solutionImage p{
        margin-top: -4.5rem;
        margin-left: 1rem;
        font-size: 13px;
    }
    .modernBusiness h1 {
        font-size: 26px;
    }
    .GoldVision h6{
        width: 75%;
        font-size: 17px;
    }
    .solutions {
        margin-top: 4rem;
        /* margin-left: 1rem; */
    }
    .essentailSolution{
        margin-bottom: 0rem;
    }
    .solutionImage img{
        height: 100%;
        width: 100%;
        margin-top: 0rem;
        margin-bottom: 0;
    
    }
    .success {
        margin-left: 0%;
        margin-bottom: 2%;
        padding-top: 3rem;
    }
  }
