.footerAddress {
  background-color: #ff3d00;
  width: auto;
  height: 6rem;
  padding: 1.3rem;
  border-radius: 15px;
}
.address {
  display: flex;
}
.address2 {
  background-color: #050a1e;
  color: #fff;
  border-radius: 20px;
  width: 32%;
  padding: 5px;
}
.address2Icon {
  margin-left: 50%;
  padding: 7px;
}
.address1 h2 {
  color: #fff;
  padding-left: 15px;
}
.copyright {
    padding-bottom: 10px;
}
.address2 h4 {
  padding-top: 2px;
}
.address1Icon {
  margin-left: -10%;
}
.address1Icon img {
  height: 2.5vw;
}
.footer {
  background: url(https://res.cloudinary.com/dixfg1bvv/image/upload/v1722601735/footer-bg_bp25vm.png);
  background-repeat: no-repeat;
  height: 26.5vw;
  width: auto;
  margin-top: -9px;
  color: #fff;
}
.footerLogo {
  height: 4vw;
}
.footerLeftSide {
  padding: 10px;
  margin-top: 3rem;
}
.footerLeftSide p {
  margin-top: 3rem;
  font-size: 19px;
}
.footerSocialMedia {
  margin-top: 9rem;
  justify-content: space-evenly;
}
.footerSocialMediaIcon {
  margin-left: 15px;
  font-size: 20px;
}
.footer ul li {
  list-style: none;
  font-size: 19px;
  margin-bottom: 20px;
}
.footer ul {
  margin-top: 3rem;
}
.footerMiddleSide {
  margin-top: 4rem;
}
.footerMiddleSide h4, .footerMiddle2Side h4 {
  margin-left: 6%;
}
.footerMiddle2Side, .footerRightSide  {
  margin-top: 4rem;
}
.footerRightSide p {
  margin-top: 3rem;
  font-size: 19px;
}
@media (max-width: 1024px) {
  .address1Icon {
    margin-left: -5px;
  }
  .address1Icon img {
    height: 3vw;
    margin-top: 10px;
  }
  .address1 h2 {
    font-size: 27px;
    margin-top: 4px;
  }
  .address2Icon {
    margin-left: 42%;
    margin-top: 5px;
  }
  .address2 {
    width: 45%;
    margin-top: 1px;
  }
  .address2 h4 {
    padding-top: 4px;
    padding-left: 3px;
  }
  .footer {
    height: 50vw;
  }
  .footerBody {
    padding: 5px;
  }
  .footerLeftSide {
    margin-top: 1rem;
  }
  .footerMiddleSide,
  .footerMiddle2Side,
  .footerRightSide {
    margin-top: 1.5rem;
  }
  .footerLeftSide p {
    margin-top: 1.5rem;
  }
  .footerMiddleSide ul,
  .footerMiddle2Side ul,
  .footerRightSide p {
    margin-top: 2.2rem;
  }
}
@media (max-width: 900px) {
  .address1Icon {
    margin-left: -5px;
  }
  .address1Icon img {
    height: 4vw;
    margin-top: 10px;
  }
  .address1 h2 {
    font-size: 19px;
    margin-top: 14px;
  }
  .address2Icon {
    margin-left: 20%;
    /* padding: 7px;     */
    margin-top: 5px;
  }
  .address2 {
    width: 61%;
    margin-top: 1px;
  }
  .address2 h4 {
    padding-top: 4px;
    padding-left: 3px;
  }
  .footer {
    height: 100%;
  }
  .footerBody {
    padding: 5px;
  }
  .footerLeftSide {
    margin-top: 1rem;
  }
  .footerMiddleSide,
  .footerMiddle2Side,
  .footerRightSide {
    margin-top: 1.5rem;
  }
  .footerLeftSide p {
    margin-top: 1.5rem;
    font-size: 14px
  }
   .footerLeftSide p,
  .footerRightSide p {
    font-size: 14px;
  }
  .footerMiddleSide ul li, .footerMiddle2Side ul li {
    margin-top: 0.6rem;
    font-size: 14px;
}
 
  .footerAddress {
    height: 6rem;
  }
  .footerMiddleSide h4,
  .footerMiddle2Side h4,
  .footerRightSide h4 {
    font-size: 20px;
    margin-left: 0;
    margin-bottom: 0;
}
.footerMiddleSide ul, .footerMiddle2Side ul, .footerRightSide p {
    margin-top: 2rem;
}
.footerLogo {
    height: 5vw;
}


}
@media (max-width: 767px) {
    .footerLeftSide{
        display: flex;
        flex-direction: column
    }
    .footerSocialMedia{
        order: 1;
    }
    .footerLogo{
        height: 10vw;
        order: 0;
    }
    .footerLeftSide p{
        order: 2;
    }
  .address1Icon {
    margin-left: -5px;
  }
  .address1Icon img {
    height: 6vw;
    margin-top: -12px;
  }
  .address1 h2 {
    font-size: 17px;
    margin-top: -7px;
  }
  .address2Icon img {
    height: 6vw;
    margin-top: -33px;
  }
  .address2Icon {
    margin-left: -10px;
    margin-top: 5px;
  }
  .address2 {
    width: auto;
    /* font-size: 11px; */
    margin-top: -9px;
    height: 9vw;
  }
  .address2 h4 {
    padding-top: 3px;
        padding-left: 2px;
        font-size: 16px;
        padding-right: 3px;
  }
  .address{
    margin:2px;
  }
  .footer {
    height: 100%;
  }
  .footerLeftSide p,
  .footerRightSide p {
    font-size: 16px;
  }
  .footer ul li {
    margin-bottom: 0rem;
  }
  .footerMiddleSide h4,
  .footerMiddle2Side h4,
  .footerRightSide h4 {
    font-size: 20px;
    margin-left: 0;
    margin-bottom: 0;
  }
  /* .footerRightSide p {
    max-height: 25.5vw;
    overflow-y: hidden;
  } */
  .footerMiddleSide ul li, .footerMiddle2Side ul li {
    margin-top: 0.6rem;
    font-size: 14px;
}
  .footerMiddleSide, .footerMiddle2Side, .footerRightSide{
    margin-top: 0;
  }
  .footerAddress {
    height: 6rem;
  }
  .footerMiddleSide ul,
  .footerMiddle2Side ul,
  .footerRightSide p {
    margin-top: 0.7rem;
  }
  .footerSocialMedia{
    margin-top: 0;
  }
  
  .footerMiddle2Side ul, .footerMiddleSide ul{
    display: flex;
    gap: 10px;
    padding: 0;
    margin-top: 0;

  }
}
@media (max-width: 400px) {
    .footerLeftSide{
        display: flex;
        flex-direction: column
    }
    .footerSocialMedia{
        order: 1;
    }
    .footerLogo{
        height: 10vw;
        order: 0;
    }
    .footerLeftSide p{
        order: 2;
    }
  .address1Icon {
    margin-left: -5px;
  }
  .address1Icon img {
    height: 6vw;
    margin-top: -12px;
  }
  .address1 h2 {
    font-size: 16px;
    margin-top: -7px;
  }
  .address2Icon img {
    height: 6vw;
    margin-top: -33px;
  }
  .address2Icon {
    margin-left: -10px;
    margin-top: 5px;
  }
  .address2 {
    width: auto;
    /* font-size: 11px; */
    margin-top: -9px;
    height: 9vw;
  }
 
  .address2 h4 {
    padding-top: 3px;
        padding-left: 2px;
        font-size: 15px;
        padding-right: 3px;
  }
  .address{
    margin:2px;
  }
  .footer {
    height: 100%;
  }
  .footerLeftSide p,
  .footerRightSide p {
    font-size: 16px;
  }
  .footer ul li {
    margin-bottom: 0rem;
  }
  .footerMiddleSide h4,
  .footerMiddle2Side h4,
  .footerRightSide h4 {
    font-size: 20px;
    margin-left: 0;
    margin-bottom: 0;
  }
  /* .footerRightSide p {
    max-height: 25.5vw;
    overflow-y: hidden;
  } */
  .footerMiddleSide ul li, .footerMiddle2Side ul li {
    margin-top: 0.6rem;
    font-size: 14px;
}
  .footerMiddleSide, .footerMiddle2Side, .footerRightSide{
    margin-top: 0;
  }
  .footerAddress {
    height: 6rem;
  }
  .footerMiddleSide ul,
  .footerMiddle2Side ul,
  .footerRightSide p {
    margin-top: 0.7rem;
  }
  .footerSocialMedia{
    margin-top: 0;
  }
  
  .footerMiddle2Side ul, .footerMiddleSide ul{
    display: flex;
    gap: 10px;
    padding: 0;
    margin-top: 0;

  }
}
