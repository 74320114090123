.blogHeading h1{
    font-size: 50px;
    font-weight: 700;
    margin-bottom: 2rem;
}
.blogLeftSide{
    margin-top: 1rem;
}
.blogHeading span{
    color: #ff3d00;
}
.Blogs{
    background-color: #f4f4f4;
}
.viewBlogsBtn{
    font-size: 20px;
    background-color: #ff3d00;
    color: #fff;
    width: 100%;
    border: #ff3d00;
    border-radius: 22px;
    height: 3rem;
}
.viewBlogs{
    margin-top: 3rem;
    padding: 2rem;
    padding-left: 56%;
}
.blogComments{
    background-color: #ff3d00;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    padding: 1rem;
    width: 75%;
    margin-left: 12%;
    margin-top: -2rem;
    position: relative;
    z-index: 1;
    border-radius:10px ;
  
}
.blogSection{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    height: 19vw;
    width: 88.5%;
    border-radius: 9px;
    
}
.blogsBody :hover{
    color: #ff3d00;
}
.blogsBody h4{
    font-size: 33px;
    font-weight: 700;
    margin-top: 1rem;
    padding-left:1rem ;
    
}
.blogsBody p{
    font-size: 20px;
    padding-left: 1rem;
}
.blogRightSideTop{
    display: flex;
    padding: 1rem;
    /* background-color: #fff; */
    
}
.blogDate{
    display: flex;
    justify-content: space-between;
    background-color: #f4f4f4;
    height: 3rem;
    /* margin-left: 1rem; */
    padding: 10px;
    width: 100%;
    border-radius: 10px;
}
.blogData h4{
    font-size: 25px;
    font-weight: 700;
    margin-top: 1rem;
    padding:10px;
    margin-left: 1rem;
}
.blogData p{
    font-size: 18px;
    margin-left: 1rem;
    padding: 10px;
}
.blogImage img{
    /* width: 12vw; */
    height:20vw;
}
.blogRightSideBottom{
    display: flex;
    padding: 1rem;
    /* background-color: #fff; */
}
.blogData{
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
@media (max-width:900px) {
    .blogLeftSide img{
        height: 100%;
        width: 100%;
    }
    .viewBlogsBtn{
        width: 12vw;
    }
    .blogSection{
        width: 100%;
        height: auto;
    }
    .blogImage img{
        height: 100%;
        width: 100%;
    }
    .blogDate{
        background-color: #fff;
        height: 4rem;
        width: 100%;
    }
    .blogHeading h1{
        font-size: 30px;
    }
    .blogsBody{
        padding: 1px;
    }
    .blogsBody h4{
        font-size: 20px;
    }
    .blogsBody p{
        font-size: 17px;
    }
    .blogBody{
        padding: 0px;
    }
    .blogBody h4{
        font-size: 20px;
        margin-bottom: 0;
        padding: 0;
    }
    .blogBody p{
        font-size: 16px;
        padding: 0;
    }
    .date p, .comments p{
        font-size: 18px;
        margin-bottom: 0;
        margin-top: 0;
        padding: 0;
    }
}
@media (max-width:767px) {
    .blogLeftSide img{
        height: 100%;
        width: 100%;
    }
    .blogSection{
        width: 100%;
        height: 62%;
    }
    .blogImage img{
        height: 100%;
        width: 100%;
    }
    .blogDate{
        background-color: #fff;
        height: 4.5rem;
        width: 100%;
    }
   
    .blogBody p{
        font-size: 11px;
        padding:1px;
    }
    .viewBlogsBtn{
        width: 25vw;
    }
    .blogHeading h1{
        font-size: 30px;
        margin-bottom: 0;
    }
    .viewBlogs {
        margin-top: 0rem;
        padding: 2rem;
        padding-left: 0%;
}
.blogsBody h4{
    font-size: 20px;
    
}
.blogsBody p{
    font-size: 16px;
}
.blogBody h4{
    font-size: 13px;
    padding: 0;
    margin-bottom: 10px;
}
.date p, .comments p{
    font-size: 10px;
    padding: 0;
    margin: 0;
}
}