.nav-head ul{
    display: flex;
    gap: 2rem;
    cursor: pointer;
    font-size: 21px;
    text-align: center;
    /* padding-top: 4px; */
    /* margin-top: 10px; */
    margin-bottom: 0;
    padding: 12px;
}
.logoImage{
    height: 3vw;
    /* width: 100%; */
}
.dropdown {
    display: flex;
    flex-direction: column;
    visibility: hidden;
    opacity: 0; 
    position: absolute;
    padding: 1rem 0;
    z-index: 2;
    background-color: #fff;
    width: 12vw;
    color: black;
    text-align: center;
}
.nav-head li:hover{
    color: #ff3d00;

}
.nav-head li{
    list-style: none;
}
 .link ul li :hover{
    color: #ff3d00;
}
.nav-head ul :hover > .dropdown{
    visibility: visible;
    opacity: 1; 
}

.arrowRightIcon{
    margin-top: -2px;
}
.link {
    text-decoration: none;
    color: #fff;
}
.dropdownLink{
    text-decoration: none;
    color: #000;
}
.dropdownLink:hover{
    color: #ff3d00;

}
.link:hover{
    color: #ff3d00;
}
.navbar{
    display: flex;
    position: fixed;
    justify-content: space-between;
    background-color: #000;
    color: #fff;
    /* padding: 1rem; */
    z-index: 3;
    width: 100%;
    padding:16px 30px ;
    margin: auto;
    left: 0;
    }
.sidebar{
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 250px;
    z-index: 999;
    background-color: rgba(14, 13, 13, 0.245);
    backdrop-filter: blur(10px);
    box-shadow: -10px 0 10px rgba(0,0, 0, 0.1 );
    display:none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.sidebar li{
    width: 100%;
}
.sidebar Link{
    width: 100%;
}
.imageLink img{
    height: 3rem;
    margin-top:4px; 
    justify-content: space-evenly;
}
.menu{
    display: none;
    margin-left: auto;
}
@media(max-width: 1440px){
    .sidebar{
        display: none;
        visibility: hidden;
    }
    .menu{
        visibility:hidden ;
    }
  
}
@media(max-width: 1024px){
    .sidebar{
        display: none;
        visibility: hidden;
    }
}
@media(max-width: 768px){
    .hideOnMobile{
        display: none;
        visibility: hidden;
    }
    .navbar{
        width: 100% ;
    }

    .menu{
        visibility: visible;
        display: flex;
        justify-items: flex-end;
       
    }
    .sidebar{
        visibility: visible;
    }
    .logoImage{
        height: 10vw;
    }
}
@media(max-width: 400px)
{
    .sidebar{
        width:100%;
        visibility: visible;
    }
    .menu{
        display: flex;
    }
    .logoImage{
        height: 10vw;
    }
    
}