.services {
  background-image: url(https://res.cloudinary.com/dixfg1bvv/image/upload/v1722235726/background1_cwesu7.png);
  width: 98.9%;
  height: 700px;
  background-repeat: no-repeat;
  position: absolute;
  margin-top: 4rem;
}
.serviceProvided {
  padding: 7rem;
}
.serviceProvided h5 {
  color: #ff3d00;
  border-radius: 30px;
  background-color: #161a2b;
  border: 2px solid #40465d;
  font-size: 20px;
  font-weight: 600;
  width: 60%;
  text-align: center;
  padding: 1rem;
  margin-top: 3rem;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  margin-left: -35px;
}
.itServicesHeader h2 {
  color: #fff;
  /* margin-bottom: 1rem; */
  font-size: 35px;
  font-weight: 700;
}
.itServicesHeader span {
  color: #ff3d00;
}
.itServicesHeader p {
  color: #fff;
  font-size: 18px;
}
.itServicesHeader {
  text-align: justify;
  margin-left: -7%;
}
.itServicesHeader h1 {
  font-weight: 800;
  font-size: 40px;
}

.itSevicesImage {
  background: url(https://res.cloudinary.com/dixfg1bvv/image/upload/v1722240113/hero-shape_jkgd5s.png);
  width: 100%;
  /* height: 20vw; */
  margin-top: 2rem;
  background-repeat: no-repeat;
}
.itSevicesImage img {
  margin-left: 16%;
  height: 35vw;
  /* z-index: 1; */
  margin-top: 2rem;
}
.exploreMore {
  font-size: 18px;
  background-color: #ff3d00;
  color: #fff;
  width: 31%;
  border: #ff3d00;
  border-radius: 22px;
  height: 3rem;
  margin-left: -35px;
}
.exploreMoreBtn {
  margin-top: 2rem;
}
.features {
  background: url(https://res.cloudinary.com/dixfg1bvv/image/upload/v1722247708/featurebg_vjrhxi.png);
  height: 17vw;
  width: 100%;
  /* box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;  */
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  text-align: center;
  background-color: #e4e4e4f0;
}
.features p {
  margin-bottom: 1rem;
  overflow: hidden;
  height: 12vw;
  font-size: 17px;
}
.feauterss {
  display: flex;
  z-index: 2;
  margin-top: -5rem;
  margin-bottom: 2rem;
}
.features img {
  justify-content: center;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
@media (max-width: 1024px) {
  .serviceProvided {
    padding: 4rem;
  }
  .serviceProvided h5{
    width: 62%;
  }
  .exploreMore{
    width: 33%;
  }
  .itSevicesImage{
    display: none;
  }
}
@media (max-width: 900px) {
    .serviceProvided {
      padding: 15px 30px;
    }
    .itSevicesImage{
        display: none;
    }
    .itServicesHeader h2{
      font-size: 26px;
    }
    .itServicesHeader p{
      font-size: 15px;
    }
  }
  @media (max-width:767px) {
    .services{
      width: 100%;
      /* height: 100%; */
    }
    .serviceProvided {
      padding: 10px 30px;
        padding-bottom: 0;
    }
    .serviceProvided h5 {
      width: 68%;
    }
    .itServicesHeader {
     margin-left: -12%;
      text-align:start ;
    }
  
  .exploreMore{
    width: auto;
  }

}
@media (max-width:400px) {
  .services{
    width: 100%;
    height: 100%;
  }
  .serviceProvided {
    padding: 10px 30px;
      padding-bottom: 0;
  }
  .serviceProvided h5 {
    width: auto;
    font-size: 17px;

  }
  .itServicesHeader {
   margin-left: -12%;
    text-align:start ;
  }
  .itServicesHeader h2{
    font-size: 18.5px;
  }
  .itServicesHeader p{
    font-size: 14px;
  }

.exploreMore{
  width: auto;
}

}